
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Components
import {
  DataBoard,
  Notebook,
  OfficeBuilding,
  Printer,
  TrendCharts,
} from "@element-plus/icons-vue";
import AuthenticationLogoutIndexVue from "@/views/authentication/logout/index.vue";
import SwitchOrganizationIndexVue from "@/components/switch-organization.vue";

// Services
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    DataBoard,
    Notebook,
    OfficeBuilding,
    Printer,
    TrendCharts,
    AuthenticationLogoutIndexVue,
    SwitchOrganizationIndexVue,
  },
})
export default class AdministratorNavigationVue extends Vue {
  isNavigationListVisible = false;

  exactRoute = "";

  get translation(): any {
    return getTranslation([
      "dashboard",
      "invoices",
      "masterData",
      "menu",
      "organizations",
      "reports",
      "settings",
    ]);
  }

  setExactRoute(): void {
    switch (this.$route.name) {
      case "AppAdministratorOrganizationsRootIndexVue":
      case "AppAdministratorOrganizationsDetailsRootIndexVue":
      case "AppAdministratorOrganizationsDetailsOrganizationMembersIndexVue":
        this.exactRoute = "organizations";
        break;
      case "AppAdministratorDashboardRootIndexVue":
        this.exactRoute = "dashboard";
        break;
      case "AppAdministratorMasterDataRootIndexVue":
      case "AppAdministratorMasterDataBanksIndexVue":
      case "AppAdministratorMasterDataPartnersRootIndexVue":
      case "AppAdministratorMasterDataPartnersDetailsIndexVue":
      case "AppAdministratorMasterDataPlaceOfCostsIndexVue":
      case "AppAdministratorMasterDataArticlesIndexVue":
      case "AppAdministratorMasterDataCountiesIndexVue":
      case "AppAdministratorMasterDataPlacesIndexVue":
      case "AppAdministratorMasterDataUnitOfMeasuresIndexVue":
      case "AppAdministratorMasterDataPaymentTypesIndexVue":
      case "AppAdministratorMasterDataInvoiceTypesIndexVue":
        this.exactRoute = "master-data";
        break;
      case "AppAdministratorInvoicesRootIndexVue":
      case "AppAdministratorInvoicesOutgoingInvoicesIndexVue":
      case "AppAdministratorInvoicesIncomingInvoicesIndexVue":
      case "AppAdministratorInvoicesExcerptsRootIndexVue":
      case "AppAdministratorInvoicesExcerptsDetailsIndexVue":
      case "AppAdministratorInvoicesInvoicePaymentsIndexVue":
      case "AppAdministratorInvoicesCompensationsIndexVue":
        this.exactRoute = "invoices";
        break;
      case "AppAdministratorReportsRootIndexVue":
      case "AppAdministratorReportsOutgoingInvoicesIndexVue":
      case "AppAdministratorReportsIncomingInvoicesIndexVue":
      case "AppAdministratorReportsPlaceOfCostsIndexVue":
      case "AppAdministratorReportsPartnersIndexVue":
        this.exactRoute = "reports";
        break;
    }
  }

  get hasUserOrganizationMemberships(): boolean {
    return store.getters.getOrganizationMembership.length > 1;
  }

  created(): void {
    this.setExactRoute();
  }
}
