import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c9dcb3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "authentication-logout" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = { class: "el-dialog__message" }
const _hoisted_5 = { class: "form--default" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwitchButton = _resolveComponent("SwitchButton")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: "#",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDialogVisible = true), ["prevent"]))
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.translation.logout), 1),
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_SwitchButton)
      ])
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.translation.logout,
      class: "el-dialog--confirmation",
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translation.areYouSure), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_button, {
            type: "primary",
            class: "el-button--primary el-button--block",
            "native-type": "submit",
            loading: _ctx.isLoading,
            onClick: _ctx.submitForm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translation.yesIAm), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"])
        ])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}