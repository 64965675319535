
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppOrganizationIndex} from "@/models/app/organization";
import {AppOrganizationMemberIndex} from "@/models/app/organization-member";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElButton,
  ElNotification,
} from "element-plus";
import {Setting} from "@element-plus/icons-vue";

// Services
import {getRequest, putRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,
    ElButton,
    ElNotification,
    Setting,
  },
})
export default class SwitchOrganizationIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  organizations: Array<AppOrganizationIndex> = [];
  organizationMember: AppOrganizationMemberIndex = new AppOrganizationMemberIndex();
  organizationMembership: Array<AppOrganizationMemberIndex> = [];

  formData = {
    organization_id: "",
  };
  formDataRules = {
    organization_id: [
      {
        required: true,
        trigger: "blur",
      },
    ],
  };

  get translation(): any {
    return getTranslation([
      "choose",
      "continue",
      "switchOrganization",
      "yourOrganizations",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await putRequest({
      uri: `/common/user/organization-membership/update`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        store.dispatch("setOrganizationMember", r.data);
        window.location.reload();
      }
    });
    this.isLoading = false;
  }

  async getUserOrganizations(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/user/organizations`,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.organizations = r.data.organizations;
      }
    });
    this.isLoading = false;
  }

  created(): void {
    this.organizationMember = store.getters.getOrganizationMember;
    this.organizationMembership = store.getters.getOrganizationMembership;

    this.getUserOrganizations();
  }
}
