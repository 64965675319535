import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d6c6e4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "switch-organization" }
const _hoisted_2 = { class: "action" }
const _hoisted_3 = { class: "form--default" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Setting = _resolveComponent("Setting")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDialogVisible = true), ["prevent"]))
      }, [
        _createVNode(_component_Setting)
      ])
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.translation.switchOrganization,
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_form, {
            model: _ctx.formData,
            rules: _ctx.formDataRules,
            ref: "formComponent",
            onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: _ctx.translation.yourOrganizations,
                prop: "organization_id"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.formData.organization_id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.organization_id) = $event)),
                    placeholder: _ctx.translation.choose
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizations, (organization) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          label: `${organization.content.name}`,
                          value: organization.id,
                          key: organization.id
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    class: "el-button--primary el-button--block",
                    "native-type": "submit",
                    loading: _ctx.isLoading,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.validateForm()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translation.continue), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "onSubmit"])
        ])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}