
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElButton,
} from "element-plus";
import {SwitchButton} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElDialog,
    ElButton,
    SwitchButton,
  },
})
export default class AuthenticationLogoutIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  get translation(): any {
    return getTranslation([
      "areYouSure",
      "logout",
      "yesIAm",
    ]);
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/authentication/logout",
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        store.dispatch("setLogout");
        this.$router.push({
          name: "HomeIndexVue",
        });
      }
    });
    this.isLoading = false;
  }
}
